import React, { ChangeEvent, FC } from 'react';
import styled from 'styled-components';

const Wrap = styled('div')``;

const Checkbox = styled('div')`
    position: relative;
    display: inline-block;
    padding: 5px 0 3px;
    label {
        position: relative;
        width: 48px;
        height: 22px;
        background: #00b1a7;
        display: inline-block;
        border-radius: 46px;
        transition: left 0.2s;
        
        & span {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            font-size: 12px;
            line-height: 14px;
            color: #fff;
        }
        &:after {
            content: '';
            position: absolute;
            border-radius: 100%;
            left: 0;
            z-index: 2;
            background: #f1f1f1;
            box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.24);
            transition: left 0.2s;
            top: 0;
            width: 22px;
            height: 22px;
        }
    }
    input{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 5;
        opacity: 0;
        cursor: pointer;
        &:checked + label{
            &:after{
                left: 26px;
            }
        }
    }
`;

const LangSpan = styled.span<{ right?: boolean }>`
    margin-left: ${({ right }) => (right ? '27px' : '5px')};
`;

interface ITogglerProps {
    changeHandler?: (ev: ChangeEvent<HTMLInputElement>) => void
    value?: boolean
}

const Toggler:FC<ITogglerProps> = ({ changeHandler, value }) => (
    <Wrap>
        <Checkbox>
            <input type="checkbox" onChange={changeHandler} checked={value} />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label>
                <LangSpan>UZ</LangSpan>
                <LangSpan right>RU</LangSpan>
            </label>
        </Checkbox>
    </Wrap>
);

export default Toggler;
