enum CategoryColors {
    FITNESS = '#ff6319',
    FOOD = '#bed600',
    HEALTH = '#5D6AE0',
    BEAUTY = '#c41b79',
    STYLE = '#C094DA',
    FAMILY = '#DF5698',
}

export default CategoryColors;
