import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Category = {
  __typename?: 'Category';
  alias: Scalars['String'];
  contents: Array<Content>;
  id: Scalars['ID'];
  localizations?: Maybe<Array<I18nCategory>>;
  name: Scalars['String'];
  params: Array<MetaContentParam>;
  priority: Scalars['Int'];
  tags?: Maybe<Array<Tag>>;
  totalContent: Scalars['Int'];
  totalContents: Scalars['Int'];
  type: Scalars['String'];
};


export type CategoryContentsArgs = {
  filter?: Maybe<ContentFilter>;
  library?: Maybe<LibraryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  paramFilters?: Maybe<ParamFilters>;
  sort?: Maybe<SortByParam>;
  tagID?: Maybe<Scalars['ID']>;
};


export type CategoryLocalizationsArgs = {
  lang: Array<Scalars['String']>;
};


export type CategoryParamsArgs = {
  name: Scalars['String'];
};


export type CategoryTotalContentsArgs = {
  filter?: Maybe<ContentFilter>;
  library?: Maybe<LibraryFilter>;
  paramFilters?: Maybe<ParamFilters>;
  sort?: Maybe<SortByParam>;
  tagID?: Maybe<Scalars['ID']>;
};

export type ComplexParam = {
  __typename?: 'ComplexParam';
  number?: Maybe<Scalars['Int']>;
  numbers?: Maybe<Array<Scalars['Int']>>;
  string?: Maybe<Scalars['String']>;
  strings?: Maybe<Array<Scalars['String']>>;
  type: Scalars['String'];
};

export type Content = {
  __typename?: 'Content';
  categories: Array<Category>;
  category: Category;
  description?: Maybe<Scalars['String']>;
  fileExt: Scalars['String'];
  id: Scalars['ID'];
  isInLibrary: Scalars['Boolean'];
  like: Like;
  link: Scalars['String'];
  listeningPosition: Scalars['Float'];
  localizations?: Maybe<Array<I18nContent>>;
  metaContentParams?: Maybe<Array<MetaContentParam>>;
  myRating?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  previews?: Maybe<Array<Preview>>;
  priority: Scalars['Int'];
  protected: Scalars['Boolean'];
  rating?: Maybe<Scalars['Float']>;
  readingPosition: Scalars['Float'];
  relations: Array<Content>;
  tags?: Maybe<Array<Tag>>;
  totalRelations: Scalars['Int'];
  type: Scalars['String'];
  view: View;
};


export type ContentIsInLibraryArgs = {
  type: LibraryType;
};


export type ContentLocalizationsArgs = {
  lang: Array<Scalars['String']>;
};


export type ContentRelationsArgs = {
  categoryID?: Maybe<Scalars['ID']>;
};


export type ContentTotalRelationsArgs = {
  categoryID?: Maybe<Scalars['ID']>;
};

export type ContentFilter = {
  typeName?: Maybe<Scalars['String']>;
};

export type I18nCategory = {
  __typename?: 'I18nCategory';
  id: Scalars['ID'];
  lang: Scalars['String'];
  name: Scalars['String'];
};

export type I18nContent = {
  __typename?: 'I18nContent';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lang: Scalars['String'];
  link: Scalars['String'];
  name: Scalars['String'];
};

export type I18nMetaContentParam = {
  __typename?: 'I18nMetaContentParam';
  id: Scalars['ID'];
  lang: Scalars['String'];
  value: Scalars['String'];
};

export type I18nTag = {
  __typename?: 'I18nTag';
  displayName: Scalars['String'];
  id: Scalars['ID'];
  lang: Scalars['String'];
};

export type LibraryFilter = {
  logicOperator: LogicOperator;
  negative: Scalars['Boolean'];
  values: Array<LibraryType>;
};

export enum LibraryType {
  Readed = 'READED',
  ReadNow = 'READ_NOW',
  Top = 'TOP'
}

export type Like = {
  __typename?: 'Like';
  id: Scalars['ID'];
  isLiked: Scalars['Boolean'];
  total: Scalars['Int'];
};

export enum LogicOperator {
  And = 'and',
  Or = 'or'
}

export type MetaContentParam = Param & {
  __typename?: 'MetaContentParam';
  complex?: Maybe<ComplexParam>;
  id: Scalars['ID'];
  localizations?: Maybe<Array<I18nMetaContentParam>>;
  name: Scalars['String'];
  value: Scalars['String'];
};


export type MetaContentParamLocalizationsArgs = {
  lang: Array<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addToLibrary?: Maybe<Content>;
  deactivateSubs?: Maybe<Scalars['Boolean']>;
  like: Like;
  listeningPosition?: Maybe<Content>;
  rating?: Maybe<Content>;
  readingPosition?: Maybe<Content>;
  removeFromLibrary?: Maybe<Content>;
  view: View;
};


export type MutationAddToLibraryArgs = {
  id: Scalars['ID'];
  type: LibraryType;
};


export type MutationLikeArgs = {
  id: Scalars['ID'];
};


export type MutationListeningPositionArgs = {
  id: Scalars['ID'];
  position: Scalars['Float'];
};


export type MutationRatingArgs = {
  id: Scalars['ID'];
  rate: Scalars['Int'];
};


export type MutationReadingPositionArgs = {
  id: Scalars['ID'];
  position: Scalars['Float'];
};


export type MutationRemoveFromLibraryArgs = {
  id: Scalars['ID'];
  type: LibraryType;
};


export type MutationViewArgs = {
  id: Scalars['ID'];
};

export enum Order {
  Asc = 'asc',
  Desc = 'desc',
  Rand = 'rand'
}

export type Param = {
  id: Scalars['ID'];
  name: Scalars['String'];
  /** Base64 encoded JSON object */
  value: Scalars['String'];
};

export type ParamFilter = {
  paramName: Scalars['String'];
  value?: Maybe<Array<Scalars['String']>>;
};

export type ParamFilters = {
  logicOperator: LogicOperator;
  negative: Scalars['Boolean'];
  params?: Maybe<Array<Maybe<ParamFilter>>>;
};

export type Point = {
  __typename?: 'Point';
  flowURL?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  service: Service;
  sid: Scalars['String'];
  sskey: Scalars['String'];
  tariff: Scalars['Int'];
  verify?: Maybe<Scalars['Boolean']>;
};

export type Preview = {
  __typename?: 'Preview';
  ext: Scalars['String'];
  id: Scalars['ID'];
  link: Scalars['String'];
  priority: Scalars['Int'];
  size: Scalars['Int'];
  type: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  languages: Array<Scalars['String']>;
  point?: Maybe<Point>;
  user?: Maybe<User>;
};

export enum SearchField {
  Creator = 'creator',
  Description = 'description',
  ExtraShortDescription = 'extraShortDescription',
  Name = 'name',
  ShortDescription = 'shortDescription'
}

export type Service = {
  __typename?: 'Service';
  alias: Scalars['String'];
  bookLibrary: Array<Content>;
  categories?: Maybe<Array<Category>>;
  category?: Maybe<Category>;
  categoryByAlias?: Maybe<Category>;
  content?: Maybe<Content>;
  contents: Array<Content>;
  id: Scalars['ID'];
  languages: Array<Scalars['String']>;
  name: Scalars['String'];
  params: Array<MetaContentParam>;
  search: Array<Content>;
  searchByField: Array<Content>;
  tag?: Maybe<Tag>;
  tagByName?: Maybe<Tag>;
  tags?: Maybe<Array<Tag>>;
  totalContent: Scalars['Int'];
  totalContents: Scalars['Int'];
};


export type ServiceBookLibraryArgs = {
  limit?: Scalars['Int'];
  offset?: Scalars['Int'];
  sort?: Maybe<SortByParam>;
  type: LibraryType;
};


export type ServiceCategoryArgs = {
  id: Scalars['ID'];
};


export type ServiceCategoryByAliasArgs = {
  alias: Scalars['String'];
};


export type ServiceContentArgs = {
  id: Scalars['ID'];
};


export type ServiceContentsArgs = {
  filter?: Maybe<ContentFilter>;
  limit?: Scalars['Int'];
  offset?: Scalars['Int'];
  paramFilters?: Maybe<ParamFilters>;
  sort?: Maybe<SortByParam>;
};


export type ServiceParamsArgs = {
  name: Scalars['String'];
};


export type ServiceSearchArgs = {
  fields?: Array<SearchField>;
  lang?: Array<Scalars['String']>;
  limit?: Scalars['Int'];
  offset?: Scalars['Int'];
  query: Scalars['String'];
  sort?: Maybe<SortByParam>;
};


export type ServiceSearchByFieldArgs = {
  fields?: Maybe<Array<SearchField>>;
  lang?: Scalars['String'];
  limit?: Scalars['Int'];
  offset?: Scalars['Int'];
  query: Scalars['String'];
  sort?: Maybe<SortByParam>;
};


export type ServiceTagArgs = {
  id: Scalars['ID'];
};


export type ServiceTagByNameArgs = {
  name: Scalars['String'];
};


export type ServiceTotalContentsArgs = {
  filter?: Maybe<ContentFilter>;
  paramFilters?: Maybe<ParamFilters>;
  sort?: Maybe<SortByParam>;
};

export type SortByParam = {
  order: Order;
  paramName?: Maybe<Scalars['String']>;
};

export type Tag = {
  __typename?: 'Tag';
  contents: Array<Content>;
  description?: Maybe<Scalars['String']>;
  displayName: Scalars['String'];
  id: Scalars['ID'];
  localizations?: Maybe<Array<I18nTag>>;
  name: Scalars['String'];
  priority: Scalars['Int'];
  totalContent: Scalars['Int'];
  totalContents: Scalars['Int'];
};


export type TagContentsArgs = {
  categoryID?: Maybe<Scalars['ID']>;
  filter?: Maybe<ContentFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  paramFilters?: Maybe<ParamFilters>;
  sort?: Maybe<SortByParam>;
};


export type TagLocalizationsArgs = {
  lang: Array<Scalars['String']>;
};


export type TagTotalContentsArgs = {
  categoryID?: Maybe<Scalars['ID']>;
  filter?: Maybe<ContentFilter>;
  paramFilters?: Maybe<ParamFilters>;
};

export type User = {
  __typename?: 'User';
  msisdn: Scalars['Int'];
  profileId: Scalars['Int'];
  sid: Scalars['String'];
  tariff: Scalars['Int'];
};

export type View = {
  __typename?: 'View';
  id: Scalars['ID'];
  isViewed: Scalars['Boolean'];
  total: Scalars['Int'];
};

export type ServiceQueryVariables = Exact<{
  limit?: Scalars['Int'];
  offset?: Scalars['Int'];
  lang: Array<Scalars['String']> | Scalars['String'];
}>;


export type ServiceQuery = (
  { __typename?: 'Query' }
  & { point?: Maybe<(
    { __typename?: 'Point' }
    & Pick<Point, 'id' | 'flowURL' | 'sskey' | 'verify'>
    & { service: (
      { __typename?: 'Service' }
      & Pick<Service, 'id'>
      & { categories?: Maybe<Array<(
        { __typename?: 'Category' }
        & ServiceCategoryFragment
      )>> }
    ) }
  )> }
);

export type CompactQueryVariables = Exact<{
  lang: Array<Scalars['String']> | Scalars['String'];
}>;


export type CompactQuery = (
  { __typename?: 'Query' }
  & { point?: Maybe<(
    { __typename?: 'Point' }
    & Pick<Point, 'id' | 'flowURL' | 'sskey' | 'verify'>
    & { service: (
      { __typename?: 'Service' }
      & Pick<Service, 'id'>
      & { categories?: Maybe<Array<(
        { __typename?: 'Category' }
        & CompactCategoryFragment
      )>> }
    ) }
  )> }
);

export type LoadMoreCategoryQueryVariables = Exact<{
  id: Scalars['ID'];
  limit?: Scalars['Int'];
  offset?: Scalars['Int'];
  lang: Array<Scalars['String']> | Scalars['String'];
}>;


export type LoadMoreCategoryQuery = (
  { __typename?: 'Query' }
  & { point?: Maybe<(
    { __typename?: 'Point' }
    & Pick<Point, 'id'>
    & { service: (
      { __typename?: 'Service' }
      & Pick<Service, 'id'>
      & { category?: Maybe<(
        { __typename?: 'Category' }
        & ServiceCategoryFragment
      )> }
    ) }
  )> }
);

export type CompactCategoryFragment = (
  { __typename?: 'Category' }
  & Pick<Category, 'id' | 'alias' | 'name' | 'totalContent' | 'priority'>
  & { localizations?: Maybe<Array<(
    { __typename?: 'I18nCategory' }
    & CategoryLocalizationsFragment
  )>> }
);

export type ServiceCategoryFragment = (
  { __typename?: 'Category' }
  & Pick<Category, 'id' | 'alias' | 'name' | 'totalContent' | 'priority'>
  & { localizations?: Maybe<Array<(
    { __typename?: 'I18nCategory' }
    & CategoryLocalizationsFragment
  )>>, contents: Array<(
    { __typename?: 'Content' }
    & ContentFragment
  )> }
);

export type ContentFragment = (
  { __typename?: 'Content' }
  & Pick<Content, 'id' | 'description' | 'link' | 'name' | 'type' | 'priority'>
  & { category: (
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'alias' | 'name' | 'totalContent'>
  ), tags?: Maybe<Array<(
    { __typename?: 'Tag' }
    & TagFragment
  )>>, previews?: Maybe<Array<(
    { __typename?: 'Preview' }
    & PreviewFragment
  )>> }
);

export type PreviewFragment = (
  { __typename?: 'Preview' }
  & Pick<Preview, 'id' | 'link' | 'type'>
);

export type TagFragment = (
  { __typename?: 'Tag' }
  & Pick<Tag, 'id' | 'displayName' | 'priority'>
  & { localizations?: Maybe<Array<(
    { __typename?: 'I18nTag' }
    & Pick<I18nTag, 'id' | 'displayName' | 'lang'>
  )>> }
);

export type CategoryByIdQueryVariables = Exact<{
  id: Scalars['ID'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  lang: Array<Scalars['String']> | Scalars['String'];
}>;


export type CategoryByIdQuery = (
  { __typename?: 'Query' }
  & { point?: Maybe<(
    { __typename?: 'Point' }
    & Pick<Point, 'id' | 'verify'>
    & { service: (
      { __typename?: 'Service' }
      & Pick<Service, 'id'>
      & { category?: Maybe<(
        { __typename?: 'Category' }
        & { localizations?: Maybe<Array<(
          { __typename?: 'I18nCategory' }
          & Pick<I18nCategory, 'id' | 'lang' | 'name'>
        )>> }
        & ServiceCategoryFragment
      )> }
    ) }
  )> }
);

export type SliderDataQueryVariables = Exact<{
  limit?: Scalars['Int'];
  offset?: Scalars['Int'];
  filter?: Maybe<ContentFilter>;
  lang: Array<Scalars['String']> | Scalars['String'];
}>;


export type SliderDataQuery = (
  { __typename?: 'Query' }
  & { point?: Maybe<(
    { __typename?: 'Point' }
    & Pick<Point, 'id' | 'verify'>
    & { service: (
      { __typename?: 'Service' }
      & Pick<Service, 'id'>
      & { contents: Array<(
        { __typename?: 'Content' }
        & Pick<Content, 'id'>
        & SliderContentFragment
      )> }
    ) }
  )> }
);

export type SliderContentFragment = (
  { __typename?: 'Content' }
  & { category: (
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'alias' | 'name'>
    & { localizations?: Maybe<Array<(
      { __typename?: 'I18nCategory' }
      & Pick<I18nCategory, 'id' | 'lang' | 'name'>
    )>> }
  ) }
  & ContentFragment
);

export type ContentByIdQueryVariables = Exact<{
  id: Scalars['ID'];
  lang: Array<Scalars['String']> | Scalars['String'];
}>;


export type ContentByIdQuery = (
  { __typename?: 'Query' }
  & { point?: Maybe<(
    { __typename?: 'Point' }
    & Pick<Point, 'id' | 'verify' | 'flowURL'>
    & { service: (
      { __typename?: 'Service' }
      & Pick<Service, 'id'>
      & { content?: Maybe<(
        { __typename?: 'Content' }
        & ContentFragment
      )> }
    ) }
  )> }
);

export type ContentIdByCategoryQueryVariables = Exact<{
  id: Scalars['ID'];
  limit?: Scalars['Int'];
  offset?: Scalars['Int'];
  filter?: Maybe<ContentFilter>;
  lang: Array<Scalars['String']> | Scalars['String'];
}>;


export type ContentIdByCategoryQuery = (
  { __typename?: 'Query' }
  & { point?: Maybe<(
    { __typename?: 'Point' }
    & Pick<Point, 'id'>
    & { service: (
      { __typename?: 'Service' }
      & Pick<Service, 'id'>
      & { category?: Maybe<(
        { __typename?: 'Category' }
        & Pick<Category, 'id'>
        & { localizations?: Maybe<Array<(
          { __typename?: 'I18nCategory' }
          & CategoryLocalizationsFragment
        )>>, contents: Array<(
          { __typename?: 'Content' }
          & Pick<Content, 'id' | 'type'>
        )> }
      )> }
    ) }
  )> }
);

export type CategoryLocalizationsFragment = (
  { __typename?: 'I18nCategory' }
  & Pick<I18nCategory, 'id' | 'lang' | 'name'>
);

export const CategoryLocalizationsFragmentDoc = gql`
    fragment CategoryLocalizations on I18nCategory {
  id
  lang
  name
}
    `;
export const CompactCategoryFragmentDoc = gql`
    fragment CompactCategory on Category {
  id
  alias
  name
  totalContent
  priority
  localizations(lang: $lang) {
    ...CategoryLocalizations
  }
}
    ${CategoryLocalizationsFragmentDoc}`;
export const TagFragmentDoc = gql`
    fragment Tag on Tag {
  id
  displayName
  priority
  localizations(lang: $lang) {
    id
    displayName
    lang
  }
}
    `;
export const PreviewFragmentDoc = gql`
    fragment Preview on Preview {
  id
  link
  type
}
    `;
export const ContentFragmentDoc = gql`
    fragment Content on Content {
  id
  description
  link
  name
  type
  priority
  category {
    id
    alias
    name
    totalContent
  }
  tags {
    ...Tag
  }
  previews {
    ...Preview
  }
}
    ${TagFragmentDoc}
${PreviewFragmentDoc}`;
export const ServiceCategoryFragmentDoc = gql`
    fragment ServiceCategory on Category {
  id
  alias
  name
  totalContent
  priority
  localizations(lang: $lang) {
    ...CategoryLocalizations
  }
  contents(limit: $limit, offset: $offset) {
    ...Content
  }
}
    ${CategoryLocalizationsFragmentDoc}
${ContentFragmentDoc}`;
export const SliderContentFragmentDoc = gql`
    fragment SliderContent on Content {
  category {
    id
    alias
    name
    localizations(lang: $lang) {
      id
      lang
      name
    }
  }
  ...Content
}
    ${ContentFragmentDoc}`;
export const ServiceDocument = gql`
    query Service($limit: Int! = 8, $offset: Int! = 0, $lang: [String!]!) {
  point {
    id
    flowURL
    sskey
    verify
    service {
      id
      categories {
        ...ServiceCategory
      }
    }
  }
}
    ${ServiceCategoryFragmentDoc}`;

/**
 * __useServiceQuery__
 *
 * To run a query within a React component, call `useServiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useServiceQuery(baseOptions: Apollo.QueryHookOptions<ServiceQuery, ServiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServiceQuery, ServiceQueryVariables>(ServiceDocument, options);
      }
export function useServiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServiceQuery, ServiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServiceQuery, ServiceQueryVariables>(ServiceDocument, options);
        }
export type ServiceQueryHookResult = ReturnType<typeof useServiceQuery>;
export type ServiceLazyQueryHookResult = ReturnType<typeof useServiceLazyQuery>;
export type ServiceQueryResult = Apollo.QueryResult<ServiceQuery, ServiceQueryVariables>;
export const CompactDocument = gql`
    query Compact($lang: [String!]!) {
  point {
    id
    flowURL
    sskey
    verify
    service {
      id
      categories {
        ...CompactCategory
      }
    }
  }
}
    ${CompactCategoryFragmentDoc}`;

/**
 * __useCompactQuery__
 *
 * To run a query within a React component, call `useCompactQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompactQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useCompactQuery(baseOptions: Apollo.QueryHookOptions<CompactQuery, CompactQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompactQuery, CompactQueryVariables>(CompactDocument, options);
      }
export function useCompactLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompactQuery, CompactQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompactQuery, CompactQueryVariables>(CompactDocument, options);
        }
export type CompactQueryHookResult = ReturnType<typeof useCompactQuery>;
export type CompactLazyQueryHookResult = ReturnType<typeof useCompactLazyQuery>;
export type CompactQueryResult = Apollo.QueryResult<CompactQuery, CompactQueryVariables>;
export const LoadMoreCategoryDocument = gql`
    query LoadMoreCategory($id: ID!, $limit: Int! = 8, $offset: Int! = 0, $lang: [String!]!) {
  point {
    id
    service {
      id
      category(id: $id) {
        ...ServiceCategory
      }
    }
  }
}
    ${ServiceCategoryFragmentDoc}`;

/**
 * __useLoadMoreCategoryQuery__
 *
 * To run a query within a React component, call `useLoadMoreCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadMoreCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadMoreCategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useLoadMoreCategoryQuery(baseOptions: Apollo.QueryHookOptions<LoadMoreCategoryQuery, LoadMoreCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoadMoreCategoryQuery, LoadMoreCategoryQueryVariables>(LoadMoreCategoryDocument, options);
      }
export function useLoadMoreCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoadMoreCategoryQuery, LoadMoreCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoadMoreCategoryQuery, LoadMoreCategoryQueryVariables>(LoadMoreCategoryDocument, options);
        }
export type LoadMoreCategoryQueryHookResult = ReturnType<typeof useLoadMoreCategoryQuery>;
export type LoadMoreCategoryLazyQueryHookResult = ReturnType<typeof useLoadMoreCategoryLazyQuery>;
export type LoadMoreCategoryQueryResult = Apollo.QueryResult<LoadMoreCategoryQuery, LoadMoreCategoryQueryVariables>;
export const CategoryByIdDocument = gql`
    query CategoryById($id: ID!, $limit: Int!, $offset: Int!, $lang: [String!]!) {
  point {
    id
    verify
    service {
      id
      category(id: $id) {
        localizations(lang: $lang) {
          id
          lang
          name
        }
        ...ServiceCategory
      }
    }
  }
}
    ${ServiceCategoryFragmentDoc}`;

/**
 * __useCategoryByIdQuery__
 *
 * To run a query within a React component, call `useCategoryByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useCategoryByIdQuery(baseOptions: Apollo.QueryHookOptions<CategoryByIdQuery, CategoryByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoryByIdQuery, CategoryByIdQueryVariables>(CategoryByIdDocument, options);
      }
export function useCategoryByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoryByIdQuery, CategoryByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoryByIdQuery, CategoryByIdQueryVariables>(CategoryByIdDocument, options);
        }
export type CategoryByIdQueryHookResult = ReturnType<typeof useCategoryByIdQuery>;
export type CategoryByIdLazyQueryHookResult = ReturnType<typeof useCategoryByIdLazyQuery>;
export type CategoryByIdQueryResult = Apollo.QueryResult<CategoryByIdQuery, CategoryByIdQueryVariables>;
export const SliderDataDocument = gql`
    query SliderData($limit: Int! = 10, $offset: Int! = 0, $filter: ContentFilter, $lang: [String!]!) {
  point {
    id
    verify
    service {
      id
      contents(limit: $limit, offset: $offset, filter: $filter) {
        id
        ...SliderContent
      }
    }
  }
}
    ${SliderContentFragmentDoc}`;

/**
 * __useSliderDataQuery__
 *
 * To run a query within a React component, call `useSliderDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useSliderDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSliderDataQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filter: // value for 'filter'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useSliderDataQuery(baseOptions: Apollo.QueryHookOptions<SliderDataQuery, SliderDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SliderDataQuery, SliderDataQueryVariables>(SliderDataDocument, options);
      }
export function useSliderDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SliderDataQuery, SliderDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SliderDataQuery, SliderDataQueryVariables>(SliderDataDocument, options);
        }
export type SliderDataQueryHookResult = ReturnType<typeof useSliderDataQuery>;
export type SliderDataLazyQueryHookResult = ReturnType<typeof useSliderDataLazyQuery>;
export type SliderDataQueryResult = Apollo.QueryResult<SliderDataQuery, SliderDataQueryVariables>;
export const ContentByIdDocument = gql`
    query ContentById($id: ID!, $lang: [String!]!) {
  point {
    id
    verify
    flowURL
    service {
      id
      content(id: $id) {
        ...Content
      }
    }
  }
}
    ${ContentFragmentDoc}`;

/**
 * __useContentByIdQuery__
 *
 * To run a query within a React component, call `useContentByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useContentByIdQuery(baseOptions: Apollo.QueryHookOptions<ContentByIdQuery, ContentByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContentByIdQuery, ContentByIdQueryVariables>(ContentByIdDocument, options);
      }
export function useContentByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContentByIdQuery, ContentByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContentByIdQuery, ContentByIdQueryVariables>(ContentByIdDocument, options);
        }
export type ContentByIdQueryHookResult = ReturnType<typeof useContentByIdQuery>;
export type ContentByIdLazyQueryHookResult = ReturnType<typeof useContentByIdLazyQuery>;
export type ContentByIdQueryResult = Apollo.QueryResult<ContentByIdQuery, ContentByIdQueryVariables>;
export const ContentIdByCategoryDocument = gql`
    query ContentIdByCategory($id: ID!, $limit: Int! = 10, $offset: Int! = 0, $filter: ContentFilter, $lang: [String!]!) {
  point {
    id
    service {
      id
      category(id: $id) {
        id
        localizations(lang: $lang) {
          ...CategoryLocalizations
        }
        contents(limit: $limit, offset: $offset, filter: $filter) {
          id
          type
        }
      }
    }
  }
}
    ${CategoryLocalizationsFragmentDoc}`;

/**
 * __useContentIdByCategoryQuery__
 *
 * To run a query within a React component, call `useContentIdByCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentIdByCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentIdByCategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filter: // value for 'filter'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useContentIdByCategoryQuery(baseOptions: Apollo.QueryHookOptions<ContentIdByCategoryQuery, ContentIdByCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContentIdByCategoryQuery, ContentIdByCategoryQueryVariables>(ContentIdByCategoryDocument, options);
      }
export function useContentIdByCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContentIdByCategoryQuery, ContentIdByCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContentIdByCategoryQuery, ContentIdByCategoryQueryVariables>(ContentIdByCategoryDocument, options);
        }
export type ContentIdByCategoryQueryHookResult = ReturnType<typeof useContentIdByCategoryQuery>;
export type ContentIdByCategoryLazyQueryHookResult = ReturnType<typeof useContentIdByCategoryLazyQuery>;
export type ContentIdByCategoryQueryResult = Apollo.QueryResult<ContentIdByCategoryQuery, ContentIdByCategoryQueryVariables>;