import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import Menu from '../Menu/Menu';
import LogoGroup from '../LogoGroup/LogoGroup';
import Breakpoints from '../../themes/constants/breakpoints';
import { IMatchParams } from '../../types/types';
import { useServiceQuery } from '../../generated/graphql';
import { PATH_PREFIX } from '../../routes/routes';
import Toggler from '../Toggler/Toggler';
import LanguageContext from '../../context/language';

const Wrapper = styled.header<{ $isMainPage: boolean, $headerPadding: number }>`
    padding-right: ${({ $headerPadding }) => `${$headerPadding.toString()}px`};
    height: ${({ theme }) => theme.paddings.paddingTop};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 3;
    min-width: 320px;
    box-sizing: border-box;
    backdrop-filter: blur(10px);
    background-color: ${({ $isMainPage }) => ($isMainPage ? 'rgba(0, 0, 0, 0.2)' : 'rgba(255, 255, 255, 0.7)')};
    @media only screen and (${Breakpoints.TABLET}) {
        background-color: ${({ $isMainPage }) => ($isMainPage ? 'rgba(0, 0, 0, 0.5)' : 'rgba(255, 255, 255, 0.7)')};
        height: 70px;
    }
    @media only screen and (${Breakpoints.LAPTOP}) {
        height: 80px;
    }
`;

const Inner = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    max-width: ${({ theme }) => theme.maxWidth};
    position: relative;
    margin: 0 auto;
    padding: 0 20px;
    @media only screen and (${Breakpoints.TABLET}) {
        height: 70px;
    }
    @media only screen and (${Breakpoints.LAPTOP}) {
        height: 80px;
    }
`;

const Header = () => {
    const { lang, toggleLang } = useContext(LanguageContext);
    const { data } = useServiceQuery({ fetchPolicy: 'no-cache', variables: { lang: [lang] } });
    const [isMenuHidden, setIsMenuHidden] = useState(true);
    const [headerPadding, setHeaderPadding] = useState(0);
    const location = useLocation();
    const toggleMenu = () => {
        setIsMenuHidden((state) => !state);
    };

    useEffect(() => {
        setIsMenuHidden(true);
    }, [location.pathname]);

    const routeMatch = useRouteMatch<IMatchParams>({
        path: `${PATH_PREFIX}/:alias`,
    });

    const isMainPage = !routeMatch?.params.alias;

    const isLaptop = useMediaQuery({ query: `(${Breakpoints.LAPTOP})` });

    useEffect(() => {
        if (!isMenuHidden && !isLaptop) {
            const widthWithScroll = document.body.clientWidth;
            document.body.style.overflow = 'hidden';
            const widthWithoutScroll = document.body.clientWidth;

            if (widthWithScroll < widthWithoutScroll) {
                const padding = widthWithoutScroll - widthWithScroll;
                document.body.style.paddingRight = `${padding.toString()}px`;
                setHeaderPadding(padding);
            }
        } else {
            document.body.style.overflow = 'unset';
            document.body.style.paddingRight = 'unset';
            setHeaderPadding(0);
        }
    }, [isMenuHidden, isLaptop]);

    return (
        <Wrapper $isMainPage={isMainPage} $headerPadding={headerPadding}>
            <Inner>
                <LogoGroup
                    isMainPage={isMainPage}
                    toggleMenu={toggleMenu}
                    hideMenu={isMenuHidden}
                />
                {data?.point?.service?.categories
                && (
                    <Menu
                        categories={data?.point?.service?.categories}
                        toggleMenu={toggleMenu}
                        hideMenu={isMenuHidden}
                    />
                )}
                <Toggler changeHandler={toggleLang} value={lang === 'uz'} />
            </Inner>
        </Wrapper>
    );
};

Header.defaultProps = {
    data: undefined,
};

export default Header;
