import fitness from '../../assets/Icons/fitness.svg';
import fitnessWhite from '../../assets/Icons/fitness_white.svg';
import food from '../../assets/Icons/food.svg';
import foodWhite from '../../assets/Icons/food_white.svg';
import health from '../../assets/Icons/health.svg';
import healthGreen from '../../assets/Icons/health_green.svg';
import beauty from '../../assets/Icons/beauty.svg';
import beautyWhite from '../../assets/Icons/beauty_white.svg';
import family from '../../assets/Icons/family.svg';
import familyWhite from '../../assets/Icons/family_white.svg';
import style from '../../assets/Icons/style.svg';
import styleWhite from '../../assets/Icons/style_white.svg';

const CategoryIcons = {
    lightTheme: {
        FITNESS: fitness,
        FOOD: food,
        HEALTH: health,
        BEAUTY: beauty,
        FAMILY: family,
        STYLE: style,
    },
    darkTheme: {
        HEALTH: healthGreen,
        FITNESS: fitnessWhite,
        FOOD: foodWhite,
        BEAUTY: beautyWhite,
        FAMILY: familyWhite,
        STYLE: styleWhite,
    },
};

export default CategoryIcons;
