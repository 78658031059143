const dictionary = {
    uz: {
        translation: {
            read_more: 'Ko\'proq o\'qish',
            get_more: 'Mavzu bo\'yicha ko\'proq',
            view_video: 'Videoni tomosha qilish',
            category_food_more: 'Hammasi ovqatlanish haqida',
            category_health_more: 'Hammasi salomatlik haqida',
            category_beauty_more: 'Hammasi go\'zallik haqida',
            category_family_more: 'Hammasi oila haqida',
            category_sport_more: 'Hammasi sport haqida',
            category_style_more: 'Hammasi uslub haqida',
            category_food: 'Ovqatlanish',
            category_health: 'Salomatlik',
            category_beauty: 'Go\'zallik',
            category_family: 'Oila',
            category_fitness: 'Sport',
            category_style: 'Uslub',
            next_article: 'Keyingi maqola',
            error: 'Nima xato ketdi ... Qayta urinib ko\'ring.',
            not_found: 'Sahifa topilmadi. Koʻproq urinib koʻring.',
            go_to_main: 'Asosiyga',
            terms_of_use: 'Foydalanish shartlari',
        },
    },
    base: {
        translation: {
            read_more: 'Читать дальше',
            get_more: 'Еще по теме',
            view_video: 'Смотреть видео',
            category_food_more: 'Всё о питании',
            category_health_more: 'Всё о здоровье',
            category_beauty_more: 'Всё о красоте',
            category_family_more: 'Всё о семье',
            category_sport_more: 'Всё о спорте',
            category_style_more: 'Всё о стиле',
            category_food: 'Питание',
            category_health: 'Здоровье',
            category_beauty: 'Красота',
            category_family: 'Семья',
            category_fitness: 'Спорт',
            category_style: 'Стиль',
            next_article: 'Следующая статья',
            error: 'Что-то пошло не так... Попробуйте еще раз.',
            not_found: 'Страница не найдена. Попробуйте еще раз.',
            go_to_main: 'На главную',
            terms_of_use: 'Правила использования',
        },
    },
};

export default dictionary;
