import React from 'react';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import arrowBack from '../../assets/Icons/arrow_back.svg';
import arrowWhite from '../../assets/Icons/arrow_white.svg';
import arrow from '../../assets/Icons/arrow.svg';
import Breakpoints from '../../themes/constants/breakpoints';
import getColor from '../../helpers/getColor';
import getIcon from '../../helpers/getIcon';
import { PATH_PREFIX } from '../../routes/routes';

const Wrapper = styled.div<{ $isMainPage?: boolean, $isContentPage?: boolean }>`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: ${({ $isMainPage }) => ($isMainPage ? '25px 20px' : '16px 10px')};
    @media only screen and (${Breakpoints.TABLET}) {
        padding: ${({ $isMainPage, $isContentPage }) => {
        if ($isMainPage) return '25px 20px';
        if ($isContentPage) return '24px 24px 24px 0';
        return '24px';
    }};
    }
`;

const Title = styled.span<{
    $color: string,
    $icon: string,
    $isMainPage?: boolean,
    $isContentPage?: boolean,
    $colored?: boolean
}>`
    position: relative;
    font-weight: 500;
    font-size: 24px;
    padding-left: ${({ $isMainPage }) => ($isMainPage ? '30px' : '0')};
    text-transform: ${({ $isMainPage }) => ($isMainPage ? 'uppercase' : 'unset')};
    color: ${({ $colored, $isMainPage }) => {
        if ($colored) return '#fff';
        if ($isMainPage) return '#333333';
        return '#000';
    }};
    cursor: ${({ $isMainPage, $isContentPage }) => (!$isMainPage && !$isContentPage ? 'auto' : 'pointer')};
    :before {
        content: '';
        display: ${({ $isMainPage }) => ($isMainPage ? 'block' : 'none')};
        width: 16px;
        height: 16px;
        border-radius: 100%;
        position: absolute;
        left: 2px;
        top: 50%;
        transform: translateY(-50%);
        background-color: ${({ color }) => color};
    }
    :after {
        content: '';
        display: ${({ $isMainPage }) => ($isMainPage ? 'block' : 'none')};
        width: 20px;
        height: 20px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background-image: ${({ $icon }) => ($icon ? `url(${$icon})` : '')};
        background-repeat: no-repeat;
        background-position: 50% 50%;
    }
    @media only screen and (${Breakpoints.LAPTOP}) {
        text-transform: uppercase;
        padding-left: 35px;
        :before, :after {
            display: block;
        }
    }
`;

const Arrow = styled.img`
`;

const ArrowBack = styled.img`
    padding: 0 10px 0 0;
    @media only screen and (${Breakpoints.TABLET}) {
        padding: 0 32px 0 0;
    }
`;

const LinkBack = styled.div`
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    :active, :visited, :hover {
        color: inherit;
    }
`;

const CategoryLink = styled(Link)`
    flex: 1;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

interface ITitleLink {
    isMainPage?: boolean;
    alias: string;
    colored?: boolean;
    isContentPage?: boolean;
}

const TitleLink = ({
    isMainPage, alias, colored, isContentPage,
}: ITitleLink) => {
    const color = alias ? getColor(alias) : '';
    const icon = alias ? getIcon(alias, (alias === 'health') || (colored && alias !== 'style')) : '';
    const isLaptop = useMediaQuery({ query: `(${Breakpoints.LAPTOP})` });
    const history = useHistory();
    const { t } = useTranslation();
    const historyBack = () => {
        history.goBack();
    };
    return (
        <Wrapper $isMainPage={isMainPage} $isContentPage={isContentPage}>
            {!isMainPage && !isLaptop && (
                <LinkBack onClick={historyBack}>
                    <ArrowBack src={arrowBack} alt="" />
                </LinkBack>
            )}
            <CategoryLink to={`${PATH_PREFIX}/${alias}`}>
                <Title
                    $color={color}
                    $icon={icon}
                    $isMainPage={isMainPage}
                    $isContentPage={isContentPage}
                    $colored={colored && alias !== 'style'}
                >
                    {t(`category_${alias}`)}
                </Title>
                {isMainPage && !isLaptop && <Arrow src={colored ? arrowWhite : arrow} alt="" />}
            </CategoryLink>
        </Wrapper>
    );
};

TitleLink.defaultProps = {
    isMainPage: false,
    isContentPage: false,
    colored: false,
};

export default TitleLink;
