import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ServiceCategoryFragment } from '../../generated/graphql';
import { PATH_PREFIX } from '../../routes/routes';

interface IFooterMenuProps {
    categories: ServiceCategoryFragment[];
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

const MenuItem = styled.div`
    height: 30px;
    padding: 0 10px;
    border: 1px solid #7A7A7A;
    border-radius: 5px;
    margin: 0 10px 10px 0;
    color: #7A7A7A;
    :hover {
        color: #e4e4e4;
        border: 1px solid #e4e4e4;
    }
`;

const MenuLink = styled(Link)`
    text-decoration: none;
    color: #7A7A7A;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    :active, :visited {
        color: #7A7A7A;
    }
`;

const FooterMenu = ({ categories }: IFooterMenuProps) => {
    const { t } = useTranslation();
    const renderFooterMenuItems = (items: ServiceCategoryFragment[]) => items
        .map(({ id, alias }: ServiceCategoryFragment) => (
            <MenuItem key={id}>
                <MenuLink to={`${PATH_PREFIX}/${alias}`}>
                    {t(`category_${alias}`)}
                </MenuLink>
            </MenuItem>
        ));
    return (
        <Wrapper>
            {renderFooterMenuItems(categories)}
        </Wrapper>
    );
};

export default FooterMenu;
