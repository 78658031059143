import theme from '../themes/constants/themes';

const getBackgroundGradient = (alias: string) => {
    switch (alias) {
        case 'food': return theme.backgroundGradients.food;
        case 'family': return theme.backgroundGradients.family;
        case 'style': return theme.backgroundGradients.style;
        default: return '';
    }
};

export default getBackgroundGradient;
