import React, { lazy } from 'react';
import {
    BrowserRouter,
    Switch,
    Route,
} from 'react-router-dom';
import { Statistic } from '@b3w/react-statistic';
import MainPage from '../../pages/MainPage/MainPage';
import { PATH_PREFIX } from '../../routes/routes';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';

const ContentPage = lazy(() => import('../../pages/ContentPage/ContentPage'));
const CategoryPage = lazy(() => import('../../pages/CategoryPage/CategoryPage'));
const OfferPage = lazy(() => import('../../pages/OfferPage/OfferPage'));

const Router = () => (
    <BrowserRouter>
        <Header />
        <Statistic path={`${PATH_PREFIX}/referer/statistic`} />
        <Switch>
            <Route key="offer" path={`${PATH_PREFIX}/user/conditions`} component={OfferPage} />
            <Route key="content" path={`${PATH_PREFIX}/:alias/:id`} component={ContentPage} />
            <Route key="category" path={`${PATH_PREFIX}/:alias`} component={CategoryPage} />
            <Route exact key="main" path={PATH_PREFIX} component={MainPage} />
        </Switch>
        <Footer />
    </BrowserRouter>
);

Router.defaultProps = {
    data: undefined,
};

export default Router;
