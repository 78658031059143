import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const ButtonLink = styled(Link)`
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    min-height: 40px;
    width: 240px;
    max-width: 320px;
    background-color: #333;
    border-radius: 6px;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 14px;
    color: #fff;
    margin: 10px auto;
    padding: 5px 10px;
    :active, :hover, :visited {
        color: #fff;
    }
`;

interface IButtonProps {
    className?: string;
    text: string;
    link: string;
}

const Button = ({ className, text, link }: IButtonProps) => (
    <ButtonLink to={link} className={className}>
        {text}
    </ButtonLink>
);

Button.defaultProps = {
    className: '',
};

export default Button;
