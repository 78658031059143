import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Breakpoints from '../../themes/constants/breakpoints';
import logo from '../../assets/Icons/logo.svg';
import barIconWhite from '../../assets/Icons/bar_icon_white.svg';
import barIconBlack from '../../assets/Icons/bar_icon_black.svg';
import crossWhite from '../../assets/Icons/cross_white.svg';
import cross from '../../assets/Icons/cross.svg';
import { PATH_PREFIX } from '../../routes/routes';

const Wrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: Pragmatica, sans-serif;
    height: 100%;
`;

const HomeLink = styled(Link)`
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
`;

const LogoWrapper = styled.div`
    height: 100%;
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    transition: 0.3s;
    @media only screen and (${Breakpoints.LAPTOP}) {
        margin: 0 20px;
    }
`;

const LogoIcon = styled.img`
    height: 34px;
    width: 34px;
    margin: 0 7px 0 0;
`;

const LogoTitle = styled.div<{ $isMainPage?: boolean, $inMenu?: boolean }>`
    height: 20px;
    font-size: 17px;
    line-height: 20px;
    font-weight: 500;
    color: #fff;
    span {
        &:nth-child(1) {
            color: ${({ $isMainPage, $inMenu }) => {
        if ($inMenu) return '#1EAE98';
        if ($isMainPage) return '#fff';
        return '#000';
    }};
        }
        &:nth-child(2) {
            color: ${({ $isMainPage }) => ($isMainPage ? '#fff' : '#000')};
        }
    }
`;

const MenuButton = styled.img`
    height: 10px;
    width: 10px;
    padding: 20px 20px 20px 10px;
    margin-left: -10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (${Breakpoints.TABLET}) {
        padding: 0 24px;
        height: 12px;
        width: 12px;
    }
    @media only screen and (${Breakpoints.LAPTOP}) {
        display: none;
    }
`;

interface ILogoGroup {
    inMenu?: boolean;
    isMainPage?: boolean;
    toggleMenu: () => void;
    hideMenu: boolean;
}

const LogoGroup = ({
    isMainPage, toggleMenu, hideMenu, inMenu,
}: ILogoGroup) => {
    const barIcon = isMainPage ? barIconWhite : barIconBlack;
    const crossMenu = isMainPage ? crossWhite : cross;
    return (
        <Wrapper>
            <MenuButton onClick={toggleMenu} src={hideMenu ? barIcon : crossMenu} alt="" />
            <LogoWrapper>
                <HomeLink to={PATH_PREFIX}>
                    <LogoIcon src={logo} alt="" />
                    <LogoTitle $isMainPage={isMainPage} $inMenu={inMenu}>
                        <span>BE</span>
                        <span>HEALTHY</span>
                    </LogoTitle>
                </HomeLink>
            </LogoWrapper>
        </Wrapper>
    );
};

LogoGroup.defaultProps = {
    isMainPage: false,
    inMenu: false,
};

export default LogoGroup;
