import React, { Suspense, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import Router from '../Router/Router';
import Loader from '../Loader/Loader';
import '../../fonts/stylesheet.css';
import { PATH_PREFIX } from '../../routes/routes';

import cross from '../../assets/Icons/cross.svg';

const AppWrapper = styled.div`
    min-width: 320px;
    max-width: 100%;
    min-height: 100vh;
    margin: 0 auto;
    background-color: #fff;
    font-family: Pragmatica, sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`;

function App() {
    useEffect(() => {
        if (window?.location && window.location.pathname === '/') {
            window.location.assign(PATH_PREFIX);
        }
    }, []);

    return (
        <AppWrapper>
            <Helmet>
                <link rel="preload" href={cross} as="image" />
            </Helmet>
            <Suspense fallback={<Loader />}>
                <Router />
            </Suspense>
        </AppWrapper>
    );
}

export default App;
