import React, { useContext } from 'react';
import styled from 'styled-components';
import CategorySection from '../../components/CategorySection/CategorySection';
import Slider from '../../components/Slider/Slider';
import { useServiceQuery, ServiceCategoryFragment } from '../../generated/graphql';
import getCategoriesList from '../../helpers/getCategoriesList';
import ErrorPage from '../ErrorPage/ErrorPage';
import LanguageContext from '../../context/language';

const Wrapper = styled.div`
    flex: 1;
`;

function MainPage() {
    const { lang } = useContext(LanguageContext);

    const { data, error } = useServiceQuery({ variables: { lang: [lang] } });

    if (error) return <ErrorPage />;

    return (
        <Wrapper>
            {data && (
                <Slider />
            )}
            {data?.point?.service.categories && getCategoriesList(data?.point?.service.categories).map(
                (category: ServiceCategoryFragment, index) => {
                    if (category) {
                        const colored = index % 2 !== 0;
                        return (
                            <CategorySection
                                key={category.id}
                                category={category}
                                authorized={!!data?.point?.verify}
                                colored={colored}
                            />
                        );
                    }

                    return null;
                },
            )}
        </Wrapper>
    );
}

export default MainPage;
