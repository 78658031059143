import { TagFragment } from '../generated/graphql';

export const getTag = (lang:string, tag:TagFragment) => {
    if (lang === 'uz') {
        return tag?.localizations?.length ? tag.localizations[0].displayName : '';
    }

    return tag.displayName;
};

export const getCategoryName = (lang:string, category:any) => {
    if (lang === 'uz') {
        return category?.localizations?.length ? category.localizations[0].name : '';
    }
    return category.name;
};
