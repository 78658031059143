import React, { MouseEventHandler, useRef } from 'react';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import { Link, useRouteMatch } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { ServiceCategoryFragment } from '../../generated/graphql';
import Breakpoints from '../../themes/constants/breakpoints';
import getColor from '../../helpers/getColor';
import getIcon from '../../helpers/getIcon';
import Portal from '../Portal/Portal';
import LogoGroup from '../LogoGroup/LogoGroup';
import { IMatchParams } from '../../types/types';
import getCategoriesList from '../../helpers/getCategoriesList';
import getMenuLinkColor from '../../helpers/getMenuLinkColor';
import { PATH_PREFIX } from '../../routes/routes';

const Blackout = styled.div<{ $hideMenu: boolean }>`
    background-color: rgba(0,0,0,0);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    overflow: hidden;
    z-index: 3;
`;

const MenuWrapper = styled.div`
    height: 100vh;
    max-width: 250px;
    padding: 0 20px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-shadow: 0 9px 18px rgba(0, 0, 0, 0.18), 0 5px 5px rgba(0, 0, 0, 0.24);
    overflow: hidden;
    @media only screen and (${Breakpoints.TABLET}) {
        max-width: 450px;
    }
    @media only screen and (${Breakpoints.LAPTOP}) {
        position: static;
        top: unset;
        left: unset;
        width: 100%;
        max-width: 100%;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex: 1;
        transform: none;
        height: 100%;
        transition: unset;
        background-color: inherit;
        box-shadow: none;
    }
`;

const MenuItem = styled.div`
    height: 40px;
    padding: 0 0 0 20px;
    @media only screen and (${Breakpoints.TABLET}) {
        height: 60px;
        padding: 0 0 0 48px;
    }
    @media only screen and (${Breakpoints.LAPTOP}) {
        height: auto;
        min-width: 40px;
        padding: 0 15px;
    }
    @media only screen and (${Breakpoints.LAPTOP_L}) {
        min-width: 60px;
    }
`;

const MenuLink = styled(Link)<{
    $color?: string,
    $icon?: string,
    $isActive: boolean,
    $isMainPage: boolean,
    $iconHover?: string,
}>`
    font-family: Pragmatica, sans-serif;
    font-weight: 500;
    font-size: 14px;
    height: 100%;
    text-decoration: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    margin: 0 auto;
    padding-left: 40px;
    color: #000;
    :before {
        content: '';
        display: ${({ $isActive }) => ($isActive ? 'block' : 'none')};
        width: 20px;
        height: 20px;
        border-radius: 100%;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background-color: ${({ color }) => color};
    }
    :after {
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background-image: ${({ $icon, $iconHover, $isActive }) => {
        if ($isActive) {
            if ($iconHover) return `url(${$iconHover})`;
            return `url(${$icon})`;
        }
        return `url(${$icon})`;
    }};
        background-repeat: no-repeat;
        background-position: 50% 50%;
    }
    :hover:after {
        background-image: ${({ $iconHover }) => ($iconHover ? `url(${$iconHover})` : '')};
    }
    :active, :visited {
        color: #000;
    }
    :hover:before {
        display: block;
    }
    @media only screen and (${Breakpoints.TABLET}) {
        font-size: 24px;
    }
    @media only screen and (${Breakpoints.LAPTOP}) {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: none;
        padding: 5px 0;
        font-size: 14px;
        color: ${({ $isMainPage, $isActive }) => getMenuLinkColor($isMainPage, $isActive)};
        :active, :visited {
            color: ${({ $isMainPage, $isActive }) => getMenuLinkColor($isMainPage, $isActive)};
        }
        :hover {
            color: ${({ $isMainPage, theme }) => ($isMainPage ? '#fff' : `${theme.colors.mainColor}`)};
            :after {
                display: none;
            }
            :before {
                display: block;
            }
        }
        :before {
            height: 6px;
            width: 6px;
            background-color: ${({ $isMainPage, theme }) => ($isMainPage ? '#fff' : `${theme.colors.mainColor}`)};
            top: 30px;
            left: 50%;
            transform: translateX(-50%);
        }
        :after {
            display: none;
        }
    }
`;

const LogoGroupWrapper = styled.div`
    height: 60px;
    margin-bottom: 10px;
    @media only screen and (${Breakpoints.TABLET}) {
        height: 70px;
        margin-bottom: 20px;
    }
`;

interface IMenuProps {
    categories: ServiceCategoryFragment[];
    toggleMenu: () => void;
    hideMenu: boolean;
}

const Menu = ({ categories, toggleMenu, hideMenu }: IMenuProps) => {
    const isLaptop = useMediaQuery({ query: `(${Breakpoints.LAPTOP})` });
    const routeMatch = useRouteMatch<IMatchParams>({
        path: `${PATH_PREFIX}/:alias`,
    });
    const { t } = useTranslation();
    const isMainPage = !routeMatch?.params.alias;

    const blackoutRef = useRef<HTMLDivElement>(null);

    const renderMenuItems = () => getCategoriesList(categories).map((category) => {
        const color = getColor(category.alias);
        const icon = getIcon(category.alias, false);
        const isActive = routeMatch?.params.alias === category.alias;
        const iconHover = (category.alias === 'health') ? getIcon(category.alias, true) : '';

        return (
            <MenuItem key={category.alias}>
                <MenuLink
                    to={`${PATH_PREFIX}/${category.alias}`}
                    $color={color}
                    $icon={icon}
                    $iconHover={iconHover}
                    $isActive={isActive}
                    $isMainPage={isMainPage}
                >
                    {t(`category_${category.alias}`)}
                </MenuLink>
            </MenuItem>
        );
    });

    const handleEvent: MouseEventHandler<HTMLDivElement> = (e) => {
        if (e.target === blackoutRef.current) toggleMenu();
        e.preventDefault();
    };

    return (
        isLaptop ? (
            <MenuWrapper>
                {renderMenuItems()}
            </MenuWrapper>
        ) : (
            <Portal>
                <CSSTransition
                    classNames="blackout"
                    in={!hideMenu}
                    timeout={300}
                    unmountOnExit
                >
                    <Blackout $hideMenu={hideMenu} onClick={handleEvent} ref={blackoutRef}>
                        <MenuWrapper className="menu">
                            <LogoGroupWrapper>
                                <LogoGroup toggleMenu={toggleMenu} hideMenu={hideMenu} inMenu />
                            </LogoGroupWrapper>
                            {renderMenuItems()}
                        </MenuWrapper>
                    </Blackout>
                </CSSTransition>
            </Portal>
        )
    );
};

export default Menu;
