import CategoryTitles from '../themes/constants/categoryTitles';

const getColor = (alias: string) => {
    switch (alias) {
        case 'fitness': return CategoryTitles.SPORT;
        case 'food': return CategoryTitles.FOOD;
        case 'health': return CategoryTitles.HEALTH;
        case 'beauty': return CategoryTitles.BEAUTY;
        case 'family': return CategoryTitles.FAMILY;
        case 'style': return CategoryTitles.STYLE;
        default: return '';
    }
};

export default getColor;
