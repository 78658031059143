enum CategoryTitles {
    FOOD = 'category_food_more',
    HEALTH = 'category_health_more',
    BEAUTY = 'category_beauty_more',
    FAMILY = 'category_family_more',
    SPORT = 'category_sport_more',
    STYLE = 'category_style_more',
}

export default CategoryTitles;
