import theme from '../themes/constants/themes';

const getMenuLinkColor = (isMainPage: boolean, isActive: boolean) => {
    if (isMainPage) return '#fff';
    if (!isMainPage && !isActive) return '#000';
    if (!isMainPage && isActive) return `${theme.colors.mainColor}`;
    return '';
};

export default getMenuLinkColor;
