import CategoryIcons from '../themes/constants/categoryIcons';

const getIcon = (alias: string, colored?: boolean) => {
    if (colored) {
        switch (alias) {
            case 'fitness': return CategoryIcons.darkTheme.FITNESS;
            case 'food': return CategoryIcons.darkTheme.FOOD;
            case 'health': return CategoryIcons.darkTheme.HEALTH;
            case 'beauty': return CategoryIcons.darkTheme.BEAUTY;
            case 'family': return CategoryIcons.darkTheme.FAMILY;
            case 'style': return CategoryIcons.darkTheme.STYLE;
            default: return '';
        }
    } else {
        switch (alias) {
            case 'fitness': return CategoryIcons.lightTheme.FITNESS;
            case 'food': return CategoryIcons.lightTheme.FOOD;
            case 'health': return CategoryIcons.lightTheme.HEALTH;
            case 'beauty': return CategoryIcons.lightTheme.BEAUTY;
            case 'family': return CategoryIcons.lightTheme.FAMILY;
            case 'style': return CategoryIcons.lightTheme.STYLE;
            default: return '';
        }
    }
};

export default getIcon;
