import React from 'react';
import styled from 'styled-components';
import desktopBigFamily from '../../assets/images/bg_desc_big_family.jpg';
import tabletFamily from '../../assets/images/bg_tablet_family.jpg';
import desktopSmallFamily from '../../assets/images/bg_desc_small_family.jpg';
import desktopBigFood from '../../assets/images/bg_desc_big_food.jpg';
import tabletFood from '../../assets/images/bg_tablet_food.jpg';
import desktopSmallFood from '../../assets/images/bg_desc_small_food.jpg';
import desktopBigStyle from '../../assets/images/bg_desc_big_style.jpg';
import tabletStyle from '../../assets/images/bg_tablet_style.jpg';
import desktopSmallStyle from '../../assets/images/bg_desc_small_style.jpg';

const BackgroundWrapper = styled.picture`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    img {
        height: 100%;
        width: 100%;
    }
`;

interface IBackgroundPicture {
    alias: string;
}

const BackgroundPicture = ({ alias }: IBackgroundPicture) => (
    <BackgroundWrapper>
        {
            alias === 'family' && (
                <>
                    <source
                        srcSet={desktopBigFamily}
                        media="(min-width: 1920px)"
                    />
                    <source
                        srcSet={desktopSmallFamily}
                        media="(min-width: 1024px)"
                    />
                    <img
                        src={tabletFamily}
                        alt=""
                    />
                </>
            )
        }
        {
            alias === 'food' && (
                <>
                    <source
                        srcSet={desktopBigFood}
                        media="(min-width: 1920px)"
                    />
                    <source
                        srcSet={desktopSmallFood}
                        media="(min-width: 1024px)"
                    />
                    <img
                        src={tabletFood}
                        alt=""
                    />
                </>
            )
        }
        {
            alias === 'style' && (
                <>
                    <source
                        srcSet={desktopBigStyle}
                        media="(min-width: 1920px)"
                    />
                    <source
                        srcSet={desktopSmallStyle}
                        media="(min-width: 1024px)"
                    />
                    <img
                        src={tabletStyle}
                        alt=""
                    />
                </>
            )
        }
    </BackgroundWrapper>
);

export default BackgroundPicture;
