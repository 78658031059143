import { createGlobalStyle } from 'styled-components';
import Breakpoints from '../constants/breakpoints';
import theme from '../constants/themes';

export default createGlobalStyle`
    body {
        background-color: #eee;
    }
    .blackout-enter {
        .menu {
            width: 0;
        }
    }
    .blackout-enter-active, .blackout-enter-done {
        .menu {
            width: 80%;
            transition: width 300ms;
        }
    }
    .blackout-exit {
        .menu {
            width: 80%;
        }
    }
    .blackout-exit-active {
        .menu {
            width: 0;
            transition: width 300ms;
        }
    }
    .swiper-container {
        height: 100%;
        z-index: 1;
        @media only screen and (${Breakpoints.LAPTOP_L}) {
            padding: 0 calc(50% - 700px);
        }
    }
    .swiper-wrapper {
        max-width: ${theme.maxWidth};
    }
    .swiper-slide {
        width: 320px;
        height: 320px;
        @media only screen and (${Breakpoints.TABLET}) {
            width: 350px;
            height: 350px;
        }
    }
    
    .lazyload-wrapper {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;
