import { ReactElement } from 'react';

import { createPortal } from 'react-dom';

interface IPortal {
    children: ReactElement | ReactElement[],
}

const Portal = ({ children }: IPortal) => {
    const portal = document.getElementById('portal');

    return portal ? createPortal(children, portal) : null;
};

export default Portal;
