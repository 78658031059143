import i18n from 'i18next';
import * as Cookies from 'js-cookie';

export const getAppLang = (): string => {
    const fromCookie = Cookies.get('app_language');

    if (fromCookie === undefined || fromCookie === 'uz') {
        return 'uz';
    }
    if (fromCookie === 'ru') {
        return 'base';
    }

    return 'uz';
};

const setI18n = (lang: string): void => {
    const appLang = lang || getAppLang();

    Cookies.set('app_language', appLang);
    i18n.changeLanguage(appLang).catch();
};

export default setI18n;
