import React, { useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import { useTranslation } from 'react-i18next';
import { ContentFragment } from '../../generated/graphql';
import Breakpoints from '../../themes/constants/breakpoints';
import { PATH_PREFIX } from '../../routes/routes';
import Loader from '../Loader/Loader';
import { getTag, getCategoryName } from '../../helpers/getTranslatedText';
import LanguageContext from '../../context/language';

import placeholderSrc from '../../assets/Icons/placeholder.svg';
import cart from '../../assets/Icons/cart.svg';
import textIcon from '../../assets/Icons/type_text.svg';
import videoIcon from '../../assets/Icons/type_video.svg';
import playButton from '../../assets/Icons/play_icon.svg';
import playButtonBig from '../../assets/Icons/play_icon_big.svg';

const Wrapper = styled.div<{ $bigSize: boolean, $additional?: boolean, $inSlider?: boolean }>`
    position: relative;
    height: ${({ $bigSize, $inSlider }) => {
        if ($bigSize) {
            if ($inSlider) return '100%';
            return '360px';
        }
        return '140px';
    }};
    width: ${({ $inSlider }) => ($inSlider ? '100%' : '380px')};
    max-width: 100%;
    display: ${({ $additional, $bigSize }) => ($additional && $bigSize ? 'none' : 'flex')};
    padding: ${({ $bigSize, $inSlider }) => {
        if ($bigSize) {
            if ($inSlider) return '0';
            return '20px';
        }
        return '20px 20px 20px 0';
    }};
    box-sizing: border-box;
    box-shadow: ${({ $inSlider }) => ($inSlider ? 'none' : '0px 10px 30px rgba(0, 0, 0, 0.25)')};
    border-radius: ${({ $inSlider }) => ($inSlider ? '0' : '10px')};
    margin: 0 auto 20px;
    overflow: hidden;
    background-color: ${({ $inSlider }) => ($inSlider ? '#E5E5E5' : '#fff')};
    @media only screen and (${Breakpoints.TABLET}) {
        margin: 0 auto;
        width: 100%;
        height: ${({ $bigSize, $inSlider }) => {
        if ($bigSize) {
            if ($inSlider) return '100%';
            return '432px';
        }
        return '168px';
    }};
    }
    @media only screen and (${Breakpoints.LAPTOP}) {
        display: flex;
        margin-bottom: ${({ $additional }) => ($additional ? '20px' : 'unset')};
    }
    @media only screen and (${Breakpoints.LAPTOP}) {
        height: ${({ $bigSize, $inSlider }) => {
        if ($bigSize) {
            if ($inSlider) return '100%';
            return '400px';
        }
        return '140px';
    }};
    }
`;

const ContentLink = styled(Link)<{ $bigSize: boolean }>`
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: ${({ $bigSize }) => ($bigSize ? 'column' : 'row')};
    text-decoration: none;
    :active, :visited, :hover {
        color: inherit;
    }
`;

const TextWrapper = styled.div<{ $bigSize: boolean, $inSlider?: boolean }>`
    position: ${({ $bigSize, $inSlider }) => (($bigSize && $inSlider) ? 'absolute' : 'relative')};
    bottom: ${({ $bigSize, $inSlider }) => (($bigSize && $inSlider) ? '20px' : 'unset')};
    left: ${({ $bigSize, $inSlider }) => (($bigSize && $inSlider) ? '0' : 'unset')};
    right: ${({ $bigSize, $inSlider }) => (($bigSize && $inSlider) ? '0' : 'unset')};
    display: flex;
    flex-direction: column;
    justify-content: ${({ $bigSize }) => ($bigSize ? 'flex-start' : 'space-between')};
    align-items: flex-start;
    flex: 1;
    color: ${({ $inSlider }) => ($inSlider ? '#fff' : '#000')};
    padding: ${({ $bigSize, $inSlider }) => {
        if ($bigSize) {
            if ($inSlider) return '0 20px';
            return '10px 0 0';
        }
        return '0 0 0 24px';
    }};
    overflow: hidden;
`;

const TypeButton = styled.div`
    padding: 0 10px;
    margin: 1px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: 30px;
    border-radius: 6px;
    font-size: 12px;
    font-weight: 400;
    color: #fff;
`;

const TypeButtonSmall = styled(TypeButton)<{ $isTextType: boolean }>`
    background-color: ${({ $isTextType, theme }) => ($isTextType ? theme.colors.text : theme.colors.mainColor)};
`;

const TypeButtonBig = styled(TypeButton)<{ $inSlider?: boolean, $isTextType: boolean }>`
    background-color: ${({ $isTextType, theme }) => ($isTextType ? theme.colors.text : theme.colors.mainColor)};
    position: ${({ $inSlider }) => ($inSlider ? 'static' : 'absolute')};
    left: ${({ $inSlider }) => {
        if (!$inSlider) return '10px';
        return 'unset';
    }};
    bottom: ${({ $inSlider }) => {
        if (!$inSlider) return '10px';
        return 'unset';
    }};
`;

const TypeIcon = styled.img`
    margin-right: 10px;
`;

const Title = styled.div<{ $bigSize: boolean, $inSlider?: boolean }>`
    position: relative;
    font-size: ${({ $bigSize }) => ($bigSize ? '14px' : '12px')};
    margin: ${({ $bigSize, $inSlider }) => {
        if ($bigSize) {
            if ($inSlider) return '0 0 15px';
            return '1px 10px 0 0';
        }
        return '1px 0';
    }};
    color: ${({ $bigSize, $inSlider }) => {
        if ($bigSize && !$inSlider) return '#8A8D8F';
        return '';
    }};
    text-shadow: ${({ $inSlider }) => ($inSlider ? '0px 1px 3px rgba(0, 0, 0, 0.65)' : 'unset')};
    overflow: hidden;
    flex: 1;
    font-weight: 400;
    :after {
        display: ${({ $inSlider }) => {
        if (!$inSlider) return 'block';
        return 'none';
    }};
        content: '';
        position: absolute;
        width: 100%;
        height: 20px;
        background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
        bottom: 0;
    }
    @media only screen and (${Breakpoints.TABLET}) {
        font-size: 14px;
    }
    @media only screen and (${Breakpoints.LAPTOP_L}) {
        font-size: ${({ $bigSize }) => ($bigSize ? '14px' : '12px')};
    }
`;

const Tag = styled.span<{ $bigSize: boolean, $inSlider?: boolean }>`
    display: ${({ $bigSize }) => ($bigSize ? 'block' : 'none')};
    font-size: 18px;
    font-weight: 500;
    padding: 0;
    margin: ${({ $bigSize, $inSlider }) => {
        if ($bigSize) {
            if ($inSlider) return '0 0 3px';
            return '0 0 6px';
        }
        return '1px 0';
    }};
    text-shadow: ${({ $inSlider }) => ($inSlider ? '0px 1px 3px rgba(0, 0, 0, 0.65)' : 'unset')};
    text-transform: lowercase;
    :first-letter {
        text-transform: uppercase;
    }
`;

const CategoryName = styled.div`
    background-color: #fff;
    min-width: 60px;
    height: 20px;
    border-radius: 2px;
    color: ${({ theme }) => theme.colors.mainColor};
    text-align: center;
    font-size: 12px;
    line-height: 20px;
    padding: 0 10px;
    box-sizing: border-box;
    margin-bottom: 10px;
    text-transform: lowercase;
`;

const ImageWrapper = styled.div<{
    $bigSize: boolean,
    $inSlider?: boolean,
}>`
    position: relative;
    height: ${({ $bigSize, $inSlider }) => {
        if ($bigSize && !$inSlider) return '240px';
        return '100%';
    }};
    width: ${({ $bigSize }) => ($bigSize ? '100%' : '100px')};
    max-width: ${({ $bigSize }) => ($bigSize ? '100%' : '100px')};
    @media only screen and (${Breakpoints.TABLET}) {
        height: ${({ $bigSize, $inSlider }) => {
        if ($bigSize && !$inSlider) return '300px';
        return '100%';
    }};
        width: ${({ $bigSize }) => ($bigSize ? '100%' : '120px')};
        max-width: ${({ $bigSize }) => ($bigSize ? '100%' : '120px')};
    }
    @media only screen and (${Breakpoints.LAPTOP}) {
        height: ${({ $bigSize, $inSlider }) => {
        if ($bigSize && !$inSlider) return '280px';
        return '100%';
    }};
        width: ${({ $bigSize }) => ($bigSize ? '100%' : '100px')};
        max-width: ${({ $bigSize }) => ($bigSize ? '100%' : '100px')};
    }
`;

const Background = styled.div<{
    $background: string,
    $withPlaceholder: boolean,
    $bigSize: boolean,
    $inSlider?: boolean,
    $isTextType: boolean,
    $showCart: boolean,
    $cartIcon: string,
}>`
    height: 100%;
    width: 100%;
    background-image: ${({ $background }) => `url(${$background})`};
    background-size: ${({ $withPlaceholder }) => ($withPlaceholder ? '30%' : 'cover')};
    background-position: 50% 50%;
    background-repeat: no-repeat;
    border-radius: ${({ $bigSize, $inSlider }) => {
        if ($bigSize) {
            if ($inSlider) return '0';
            return '10px';
        }
        return '0 10px 10px 0';
    }};
    :after {
        content: '';
        display: ${({ $isTextType, $withPlaceholder, $inSlider }) => ($isTextType || $withPlaceholder || $inSlider
        ? 'none' : 'block')};
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        left: 50%;
        height: ${({ $bigSize }) => ($bigSize ? '60px' : '32px')};
        width: ${({ $bigSize }) => ($bigSize ? '60px' : '32px')};
        background-image: ${({ $bigSize }) => ($bigSize ? `url(${playButtonBig})` : `url(${playButton})`)};
        background-repeat: no-repeat;
    }
    :before {
        content: '';
        position: absolute;
        display: ${({ $showCart }) => ($showCart ? 'block' : 'none')};
        top: ${({ $bigSize, $inSlider }) => {
        if ($bigSize && !$inSlider) return '10px';
        return 'unset';
    }};
        left: ${({ $bigSize, $inSlider }) => {
        if ($inSlider && $bigSize) return 'unset';
        return '10px';
    }};
        right: ${({ $bigSize, $inSlider }) => {
        if ($inSlider && $bigSize) return '20px';
        return '10px';
    }};
        bottom: ${({ $bigSize, $inSlider }) => {
        if ($bigSize) {
            if ($inSlider) return '24px';
            return 'unset';
        }
        return '10px';
    }};
        width: 24px;
        height: 24px;
        border-radius: 100%;
        background: ${({ $cartIcon, theme }) => `url(${$cartIcon}) 50% 50% no-repeat ${theme.colors.mainColor}`};
    }
`;

const SlideInner = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
    background-size: cover;
    background-position: 50% center;
    background-color: #E5E5E5;
`;

interface IContentItemProps {
    item: ContentFragment;
    bigSize: boolean;
    inSlider?: boolean;
    additional?: boolean;
    authorized: boolean;
}

const ContentItem = ({
    item,
    bigSize,
    inSlider,
    additional,
    authorized,
}: IContentItemProps) => {
    const { t } = useTranslation();
    const { lang } = useContext(LanguageContext);
    const imageSrc = item.previews?.length ? `${PATH_PREFIX}/gate/f/${item.previews[0].link}` : placeholderSrc;
    const tag = item.tags?.length ? getTag(lang, item.tags[0]) : '';
    const withPlaceholder = imageSrc === placeholderSrc;
    const isTextType = item.type === 'html';
    const type = isTextType ? t('read_more') : t('view_video');
    const typeIcon = isTextType ? textIcon : videoIcon;
    const showCart = !isTextType && !authorized;
    const contentLink = `${PATH_PREFIX}/${item.category.alias}/${item.id.replace('content/', '')}`;

    const renderTextWrapper = () => (
        <TextWrapper $bigSize={bigSize} $inSlider={inSlider}>
            {inSlider && (
                <CategoryName>
                    #
                    { getCategoryName(lang, item.category) }
                </CategoryName>
            )}
            <Tag $bigSize={bigSize} $inSlider={inSlider}>{tag}</Tag>
            <Title $bigSize={bigSize} $inSlider={inSlider}>{item.name}</Title>
            {(!bigSize || inSlider) && (
                <TypeButtonSmall $isTextType={isTextType}>
                    <TypeIcon src={typeIcon} />
                    <span>{type}</span>
                </TypeButtonSmall>
            )}
        </TextWrapper>
    );

    return (
        <Wrapper $bigSize={bigSize} $additional={additional} $inSlider={inSlider}>
            <ContentLink to={contentLink} $bigSize={bigSize}>
                {inSlider ? (
                    <SlideInner
                        data-background={imageSrc}
                        className="swiper-lazy"
                    >
                        <div className="swiper-lazy-preloader swiper-lazy-preloader-white" />
                        {renderTextWrapper()}
                    </SlideInner>
                ) : (
                    <>

                        <ImageWrapper
                            $bigSize={bigSize}
                            $inSlider={inSlider}
                        >
                            <LazyLoad
                                height={200}
                                once
                                placeholder={<Loader />}
                            >
                                <Background
                                    $withPlaceholder={withPlaceholder}
                                    $background={imageSrc}
                                    $bigSize={bigSize}
                                    $isTextType={isTextType}
                                    $showCart={showCart}
                                    $cartIcon={cart}
                                />
                            </LazyLoad>
                            {bigSize && (
                                <TypeButtonBig $inSlider={inSlider} $isTextType={isTextType}>
                                    <TypeIcon src={typeIcon} />
                                    <span>{type}</span>
                                </TypeButtonBig>
                            )}
                        </ImageWrapper>
                        {renderTextWrapper()}
                    </>
                )}
            </ContentLink>
        </Wrapper>
    );
};

ContentItem.defaultProps = {
    additional: false,
    inSlider: false,
};

export default ContentItem;
