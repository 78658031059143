import CategoryColors from '../themes/constants/categoryColors';

const getColor = (alias: string) => {
    switch (alias) {
        case 'fitness': return CategoryColors.FITNESS;
        case 'food': return CategoryColors.FOOD;
        case 'health': return CategoryColors.HEALTH;
        case 'beauty': return CategoryColors.BEAUTY;
        case 'style': return CategoryColors.STYLE;
        case 'family': return CategoryColors.FAMILY;
        default: return '';
    }
};

export default getColor;
