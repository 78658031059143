import React, { useEffect, useState } from 'react';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { offsetLimitPagination } from '@apollo/client/utilities';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import App from '../App/App';
import LanguageContext from '../../context/language';
import ThemeProvider from '../../themes/provider/ThemeProvider';
import { PATH_PREFIX } from '../../routes/routes';
import setI18n, { getAppLang } from '../../helpers/setLanguage';
import dictionary from '../../dictionary';

export const client = new ApolloClient({
    uri: `${PATH_PREFIX}/api/query`,
    connectToDevTools: true,
    cache: new InMemoryCache({
        typePolicies: {
            Preview: {
                keyFields: ['id'],
            },
            Category: {
                keyFields: ['id'],
                fields: {
                    contents: offsetLimitPagination(),
                },
            },
            Tag: {
                keyFields: ['id'],
                fields: {
                    contents: offsetLimitPagination(),
                },
            },
            Service: {
                keyFields: ['id'],
                fields: {
                    contents: offsetLimitPagination(),
                },
            },
            Content: {
                keyFields: ['id'],
            },
        },
    }),
});

i18n
    .use(initReactI18next)
    .init({
        lng: getAppLang(),
        resources: dictionary,
        keySeparator: false,
        interpolation: {
            escapeValue: false,
        },
    });

const Root = () => {
    const [appLang, setAppLang] = useState(getAppLang());

    const setLanguage = () => {
        if (appLang === 'uz') {
            setAppLang('base');
        } else {
            setAppLang('uz');
        }
    };

    useEffect(() => {
        setI18n(appLang);
    }, [appLang]);

    return (
        <ApolloProvider client={client}>
            <ThemeProvider>
                <LanguageContext.Provider value={{ lang: appLang, toggleLang: setLanguage }}>
                    <App />
                </LanguageContext.Provider>
            </ThemeProvider>
        </ApolloProvider>
    );
};

export default Root;
